import { FC } from 'react';
import RootRoutes from './routes/RootRoutes';
import { useAppSelector } from '../../hooks/index';
import Toast from '../common/toast/Toast';

const App: FC = () => {
  const { message, type } = useAppSelector((state) => state.toast);
  /* IT WILL GLOBALLY REMOVE CONSOLE LOGS, If doing development below line */
  // console.log = () => {}
  return (
    <>
      {message && <Toast type={type} message={message} />}
      <RootRoutes />
    </>
  );
};

export default App;
