import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from '../../../components/common/table/Table'
import { medicalTableHeaderData } from '../../../constants/data'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loader from '../../../components/common/spinner/Loader'
import {
  activeMedicalCenterById,
  getAllMedicalCenter,
} from '../../../redux/features/medical-center/medicalCenterAsynActions'
import Popup from '../../../components/common/popup/Popup'
import NotesPopup from '../../../components/common/modal/notes-popup/NotesPopup'
import { getAllPackage } from '../../../redux/features/package/packageAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import DeletePopup from '../../../components/common/modal/delete-popup/DeletePopup'
import CreatedUsersPopup from '../../../components/common/created-users-popup/CreatedUsersPopup'
import { trimValue } from '../../../utils/utils'
import Button from '../../../components/common/button/Button'
import { clearState } from '../../../redux/features/medical-center/medicalCenterSlice'
import Select from 'react-select'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { SearchIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import styles from './medicalCenter.module.scss'
import moment from 'moment'

interface IMedicalCenter {}

const MedicalCenter: FC<IMedicalCenter> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { packageData } = useAppSelector((state) => state.package)
  const { mcLoading, isLoading, medicalCenterData } = useAppSelector(
    (state) => state.medicalCenter
  )

  /* Pagination Dependency */
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  /* Pagination Dependency */

  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [notesPopData, setNotesPopData] = useState({})
  const [deletePopData, setDeletePopData] = useState({})
  const [showCreateUsers, setShowCreateUsers] = useState<boolean>(false)
  const [createUsersPopData, setCreateUsersPopData] = useState({})
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [packageFilter, setPackageFilter] = useState<any>(null)
  const [searchValue, setSearchValue] = useState('')

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // Api call for Get All Package
  useEffect(() => {
    dispatch(getAllPackage(requestGenerator({})))
  }, [dispatch])

  // notes modal close
  const handleModalClose = () => {
    setShowNotes(false)
  }
  // handle MC notes
  const handleMCNotes = (item: any) => {
    setShowNotes(!showNotes)
    setNotesPopData(item)
  }

  // delete modal close
  const handleDeleteModalClose = () => {
    setShowDelete(false)
  }
  // handle MC delete
  const handleDeleteMC = (item: any) => {
    setShowDelete(!showDelete)
    setDeletePopData(item)
  }

  // MC record getbyId
  const handleGetMcById = (item: any) => {
    item &&
      navigate(`managemedicalcenter`, {
        state: {
          id: item,
        },
      })
    dispatch(clearState())
  }

  // create user modal close
  const handleCreateUsersModalClose = () => {
    setShowCreateUsers(false)
  }

  // create user
  const handleCreateUsers = (item: any) => {
    setShowCreateUsers(!showCreateUsers)
    setCreateUsersPopData(item)
  }

  // handletoggle
  const [toggle, setToggle] = useState<boolean>(true)
  const [toogleValue, setToggleValue] = useState()

  const handleActiveMC = (item: any) => {
    if (item?.config !== null) {
      setToggle(!toggle)
      setToggleValue(item?._id)
      let activePayload = {
        id: item?._id,
        data: {
          is_active: !item?.is_active,
        },
      }

      dispatch(activeMedicalCenterById(requestGenerator(activePayload)))
    } else {
      return
    }
  }

  /* function defination for search */
  const handleSearch = () => {
    setPageIndex(1)
    if (searchValue?.length > 0) {
      const requestData = {
        search: searchValue,
        pkg: packageFilter?.value,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }
  /* function defination for search */

  /*useEffect for get API*/
  useEffect(() => {
    const requestData = {
      search: searchValue,
      pkg: packageFilter?.value,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllMedicalCenter(requestGenerator(requestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [dataPerPage, pageIndex, packageFilter])
  /*useEffect for get API*/

  return (
    <>
      {(isLoading || mcLoading) && <Loader />}
      {showNotes && (
        <Popup
          Children={NotesPopup}
          popData={notesPopData}
          handleClose={() => handleModalClose()}
        />
      )}
      {showDelete && (
        <Popup
          Children={DeletePopup}
          popData={deletePopData}
          handleClose={() => handleDeleteModalClose()}
        />
      )}
      {showCreateUsers && (
        <Popup
          Children={CreatedUsersPopup}
          popData={createUsersPopData}
          handleClose={() => handleCreateUsersModalClose()}
        />
      )}

      <div className={styles.mainContainer}>
        <div className={styles.addMedicalCenter}>
          <p className={styles.medicalCenterTitle}>Medical Center List</p>
          <Button
            title="Add Medical Center"
            customClass={styles.medicalCenterButtonStyle}
            handleClick={() => {
              dispatch(clearState())
              navigate('managemedicalcenter')
            }}
          />
        </div>
        <div className={styles.addMedicalCenter}>
          <p className={styles.medicalCenterTitle}></p>
          <Button
            title="Medical center utility"
            customClass={styles.medicalCenterButtonStyle}
            handleClick={() => {
              navigate('medicalcenter-utility')
            }}
          />
        </div>
        {/* Search Container */}
        <div className={styles.searchContainer}>
          <div className={styles.search}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder="Search by Name"
              value={searchValue}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearchValue(e.target.value)
                if (searchValue !== '' && e.target.value.length === 0) {
                  setPageIndex(1)
                  setDataPerPage(10)
                  const requestData = {
                    search: '',
                    pkg: '',
                    page: 1,
                    pageSize: 10,
                  }
                  dispatch(
                    getAllMedicalCenter(requestGenerator(requestData))
                  ).then((result) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                  setPackageFilter(null)
                }
              }}
            />
            <div className={styles.searchButton} onClick={handleSearch}>
              <SearchIcon
                fillColor={colors.white1}
                customClass={styles.searchIcon}
              />
            </div>
          </div>
          <div className={styles.fileterContainer}>
            <Select
              className={styles.selectInputField}
              placeholder="Select Package"
              closeMenuOnSelect={true}
              components={{ DropdownIndicator }}
              isSearchable={false}
              isClearable={true}
              options={packageData?.map((item: any) => ({
                label: item?.name,
                value: item?._id,
              }))}
              value={packageFilter || ''}
              onChange={(e: any) => {
                setPageIndex(1)
                setPackageFilter(e)
              }}
              maxMenuHeight={200}
              styles={{
                control: (provided: any) => ({
                  ...provided,
                  width: '220px',
                  height: '42px',
                  cursor: 'pointer',
                }),
              }}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <Table
            tableHeaderData={medicalTableHeaderData}
            tableRowData={medicalCenterData.map((item: any) => {
              return {
                ...item,
                // expiry_date: utcToDate(item.expiry_date),
                expiry_date: moment(item.expiry_date).format('DD-MM-yyyy'),
              }
            })}
            handleAction={handleGetMcById}
            handleNotes={handleMCNotes}
            handleDeleteAction={handleDeleteMC}
            handleCreateUsers={handleCreateUsers}
            handleActiveMC={handleActiveMC}
            toogleValue={toogleValue}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default MedicalCenter
