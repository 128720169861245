import React, { FC, useEffect, useState } from "react";
import Styles from "./medicalCenterUtility.module.scss";
import Button from "../../../components/common/button/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  addDataBaseUri,
  awsConnectionAction,
  getAllMedicalCenterUtility,
  updateConfigData,
} from "../../../redux/features/medical-center-utility/medicalCenterUtilityAsynActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { SubmitHandler, useForm } from "react-hook-form";
import { medicalUtilityValidators } from "../../../form-validators/medicalCenterUtilityValidater";
import {
  ACCESS_KEY,
  BUCKET_NAME,
  DATABASE_URI,
  MEDICAL_CENTER,
  REGION,
  SECRET_KEY,
} from "../../../constants/constant";
import { trimValue } from "../../../utils/utils";
import {
  IMedicalCenterAWS,
  IMedicalCenterAllData,
  IMedicalCenterUtilityForm,
} from "../../../interfaces/interfaces";
import {
  setAllData,
  setURIData,
} from "../../../redux/features/medical-center-utility/medicalCenterUtilitySlice";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/spinner/Loader";

// Define the props interface for the MedicalCenterUtility component
interface IMedicalCenterUtility {}

// MedicalCenterUtility component
const MedicalCenterUtility: FC<IMedicalCenterUtility> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // for select id******************************
  const [selectedId, setSelectedId] = useState<any>("");
  const [dataa, setDataa] = useState<any>();
  const [dataaa, setDataaa] = useState<any>();
  const handleSelectChange = (e: any) => {
    setSelectedId(e.target.value);
  };
  // for select id******************************

  const { proactMedicalCenterUtility, setURIDATA, setAllDATA, isLoading } =
    useAppSelector((state) => state.medicalCenterUtility);
  // for database URI form*********************************
  const {
    register: register1,
    handleSubmit: handleSubmitFirstForm,
    watch: watch1,
    setValue: value1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm<IMedicalCenterUtilityForm>({});

  const onSubmit1: SubmitHandler<IMedicalCenterUtilityForm> = (data) => {
    let requestDatas = {
      db_uri: data.db_uri,
    };
    dispatch(addDataBaseUri(requestGenerator(requestDatas))).then((e) => {
      if (e.type === "getAllMedicalUtylity/addDataBaseUriData/fulfilled") {
        setDataaa(true);
        dispatch(setURIData(data));
      } else {
        setDataaa(false);
      }
    });
  };
  useEffect(() => {
    dispatch(getAllMedicalCenterUtility(requestGenerator({})));
  }, []);
  // for database URI form*********************************

  // for AWS form*********************************
  const {
    register: register2,
    handleSubmit: handleSubmitSecondForm,
    reset: reset2,
    setValue: value2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm<IMedicalCenterAWS>({});
  const onSubmit2: SubmitHandler<IMedicalCenterAWS> = (data) => {
    let requestDataAWS = {
      aws: {
        key: data.access_key,
        secretKey: data.secretKey,
        region: data.region,
        bucket_name: data.bucket_name,
      },
    };
    dispatch(awsConnectionAction(requestGenerator(requestDataAWS))).then(
      (e) => {
        if (e.type === "getAllMedicalUtylity/awsConnectionData/fulfilled") {
          dispatch(setAllData(data));
          setDataa(true);
        } else {
          setDataa(false);
        }
      }
    );
  };
  // for AWS form*********************************

  // for submit all data***********************************
  const handleClickConfig = () => {
    const configData = {
      id: selectedId,
      db_uri: setURIDATA?.db_uri,
      aws: {
        key: setAllDATA?.access_key,
        secretKey: setAllDATA?.secretKey,
        region: setAllDATA?.region,
        bucket_name: setAllDATA?.bucket_name,
      },
    };
    dispatch(updateConfigData(requestGenerator(configData))).then((e: any) => {
      navigate("/medicalcenter");
    });
  };
  const handleReset = () => {
    reset1();
    reset2();
    setDataaa("");
    setDataa("");
  };

  const handleBack = () => {
    navigate("/medicalcenter");
  };
  return (
    <>
      {/* set dropdown value and database uri form******************************* */}
      {isLoading && <Loader />}
      <div className={Styles.mainCenterUtility}>
        <form onSubmit={handleSubmitFirstForm(onSubmit1)}>
          <div className={Styles.medicalCenterDropDownMain}>
            {/* Medical Center dropdown */}
            <div className={Styles.dropdowItemData}>
              <label className={Styles.medicalLable}>Medical Center</label>
              <select
                className={Styles.medicalSelect}
                {...register1(
                  MEDICAL_CENTER,
                  medicalUtilityValidators[MEDICAL_CENTER]
                )}
                value={selectedId}
                onChange={handleSelectChange}
              >
                <option value="">Select Medical Center</option>
                {proactMedicalCenterUtility.map((item: any) => (
                  <React.Fragment key={item._id}>
                    <option value={item._id}>{item.mc_name}</option>
                  </React.Fragment>
                ))}
              </select>
              <div className={Styles.errorContainers}>
                <p className={Styles.errorText}>
                  {errors1[MEDICAL_CENTER] && (
                    <p className="dashboardFormError">
                      {errors1[MEDICAL_CENTER].message as any}
                    </p>
                  )}
                </p>
              </div>
            </div>
            {/* Database Configuration */}
            <div className={Styles.configurationMain}>
              <span className={Styles.configurationHeader}>
                Database Configuration
              </span>
              <div className={Styles.fileStorageForm}>
                <div className={Styles.fileStorageContainer}>
                  <div className={Styles.fileStorageInput}>
                    <label htmlFor="" className={Styles.fileStorageLabel}>
                      Database URI
                    </label>
                    <input
                      type="text"
                      className={Styles.fileStorageInput}
                      {...register1(
                        DATABASE_URI,
                        medicalUtilityValidators[DATABASE_URI]
                      )}
                      placeholder="Enter database uri"
                      disabled={dataaa ? true : false}
                      onChange={(e: any) => trimValue(e)}
                    />
                  </div>
                  <div className={Styles.errorContainer}>
                    <p className={Styles.errorText}>
                      {errors1[DATABASE_URI] && (
                        <p className="dashboardFormError">
                          {errors1[DATABASE_URI].message as any}
                        </p>
                      )}
                    </p>
                  </div>
                </div>
                <div className={Styles.fileStorageContainer}>
                  <div className={Styles.fileStorageInput}>
                    <Button
                      title="Test Connection"
                      customClass={Styles.medicalCenterButtonStyle}
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* File Storage Configuration */}
          </div>
        </form>
        {/* set dropdown value and database uri******************************* */}
        {/* ********************************************************************************************************* */}
        {/* set aws form******************************* */}
        <form onSubmit={handleSubmitSecondForm(onSubmit2)}>
          <div className={Styles.fileStorageMain}>
            <span className={Styles.fileStorageHeader}>
              File Storage Configuration
            </span>
            <div className={Styles.fileStorageForm}>
              <div className={Styles.fileStorageContainer}>
                <div className={Styles.fileStorageInput}>
                  <label htmlFor="" className={Styles.fileStorageLabel}>
                    Access Key
                  </label>
                  <input
                    type="text"
                    className={Styles.fileStorageInput}
                    {...register2(
                      ACCESS_KEY,
                      medicalUtilityValidators[ACCESS_KEY]
                    )}
                    placeholder="Enter access key"
                    disabled={dataa ? true : false}
                    onChange={(e: any) => trimValue(e)}
                  />
                </div>
                <div className={Styles.errorContainer}>
                  <p className={Styles.errorText}>
                    {errors2[ACCESS_KEY] && (
                      <p className="dashboardFormError">
                        {errors2[ACCESS_KEY].message as any}
                      </p>
                    )}
                  </p>
                </div>
              </div>
              <div className={Styles.fileStorageContainer}>
                <div className={Styles.fileStorageInput}>
                  <label htmlFor="" className={Styles.fileStorageLabel}>
                    Bucket Name
                  </label>
                  <input
                    type="text"
                    className={Styles.fileStorageInput}
                    {...register2(
                      BUCKET_NAME,
                      medicalUtilityValidators[BUCKET_NAME]
                    )}
                    placeholder="Enter S3 bucket name"
                    disabled={dataa ? true : false}
                    onChange={(e: any) => trimValue(e)}
                  />
                </div>
                <div className={Styles.errorContainer}>
                  <p className={Styles.errorText}>
                    {errors2[BUCKET_NAME] && (
                      <p className="dashboardFormError">
                        {errors2[BUCKET_NAME].message as any}
                      </p>
                    )}
                  </p>
                </div>
              </div>

              <div className={Styles.fileStorageContainer}>
                <div className={Styles.fileStorageInput}>
                  <label htmlFor="" className={Styles.fileStorageLabel}>
                    Secret Key
                  </label>
                  <input
                    type="text"
                    className={Styles.fileStorageInput}
                    {...register2(
                      SECRET_KEY,
                      medicalUtilityValidators[SECRET_KEY]
                    )}
                    placeholder="Enter secret key"
                    disabled={dataa ? true : false}
                    onChange={(e: any) => trimValue(e)}
                  />
                </div>
                <div className={Styles.errorContainer}>
                  <p className={Styles.errorText}>
                    {errors2[SECRET_KEY] && (
                      <p className="dashboardFormError">
                        {errors2[SECRET_KEY].message as any}
                      </p>
                    )}
                  </p>
                </div>
              </div>
              <div className={Styles.fileStorageContainer}>
                <div className={Styles.fileStorageInput}>
                  <label htmlFor="" className={Styles.fileStorageLabel}>
                    Bucket Region
                  </label>
                  <input
                    type="text"
                    className={Styles.fileStorageInput}
                    {...register2(REGION, medicalUtilityValidators[REGION])}
                    placeholder="Enter S3 bucket region"
                    disabled={dataa ? true : false}
                    onChange={(e: any) => trimValue(e)}
                  />
                </div>
                <div className={Styles.errorContainer}>
                  <p className={Styles.errorText}>
                    {errors2[REGION] && (
                      <p className="dashboardFormError">
                        {errors2[REGION].message as any}
                      </p>
                    )}
                  </p>
                </div>
              </div>
              <div className={Styles.fileStorageContainer}>
                <div className={Styles.fileStorageInput}>
                  {/* <label htmlFor="" className={Styles.fileStorageLabel}></label> */}
                  <Button
                    title="Test Connection"
                    customClass={Styles.medicalCenterButtonStyle}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* Submit Button */}
        <div className={Styles.mainButtons}>
          {dataa && dataaa === true ? (
            <div className={Styles.submitButton}>
              <Button title="Submit" handleClick={handleClickConfig} />
            </div>
          ) : (
            ""
          )}
          {dataa && dataaa === true ? (
            <div className={Styles.submitButton}>
              <Button title="Reset"   customClass={Styles.resetBtn} handleClick={handleReset} />
            </div>
          ) : (
            ""
          )}
          {dataa && dataaa === true ? (
            <div className={Styles.submitButton}>
              <Button title="Back" type="button" handleClick={handleBack} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default MedicalCenterUtility;
