/* eslint-disable no-lone-blocks */
import { IAPIResponse, IAPIPayload } from '../interfaces/apiInterface'
import { IToastType } from '../interfaces/interfaces'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { failure, globalError, success } from '../constants/data'
import { setMessage } from '../redux/features/toast/toastSlice'

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const handleRefClick = (ref: any) => {
  // console.log('click', ref)
  if (ref.current) {
    ref.current.click()
  }
}

export const getAPIResponse = (response: IAPIResponse): IAPIResponse => {
  const { dk, payloadResponse, rc } = response
  return { dk, payloadResponse, rc }
}

export const getToastPayload = (
  msg: string,
  type: IToastType,
  fieldMSG?: string
) => {
  // console.log('field msg', fieldMSG)
  const payload = {
    message: fieldMSG ? `${msg} - ${fieldMSG}` : msg,
    type: type,
  }
  return payload
}
//Funtion for Create Base64

const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    let baseURL: any = ''
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}

//Funtion for Create DataURI

export const dataURI = async (file: File) => {
  try {
    const result = await getBase64(file)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const createAsyncThunkForSlice = (
  type: string,
  resolver: any,
  options?: { isEncrypt?: boolean; isToast?: boolean; extraParam?: boolean }
) =>
  createAsyncThunk(
    type,
    async (payload: IAPIPayload, { dispatch, rejectWithValue }) => {
      try {
        const res: any = await resolver(payload, options?.extraParam)
        // console.log("res in slice>>", res);
        if (res?.rc === 0) {
          // options?.isEncrypt && res?.dk && dispatch(setEncryptionKey(res?.dk)) // set static key for cryptography
          {
            options?.isToast &&
              dispatch(
                setMessage(
                  getToastPayload(res.payloadResponse.message, success)
                )
              )
          } // set toast notifaction msg for response
          return res.payloadResponse.data
        } else if (res?.payloadResponse?.sc === 401) {
          dispatch(
            setMessage({
              message: 'Your session has been expired, please login again.',
              type: failure,
            })
          )
          setTimeout(() => {
            localStorage.clear()
            window.location.href = '/'
          }, 1000)

          throw new Error(res.payloadResponse.error[0].message)
        } else {
          {
            options?.isToast &&
              dispatch(
                setMessage(
                  getToastPayload(
                    res.payloadResponse.error[0].message ?? globalError,
                    failure
                  )
                )
              )
          } // set toast notifaction msg for response
          throw new Error(res.payloadResponse.error[0].message)
        }
      } catch (error: any) {
        {
          options?.isToast &&
            dispatch(
              setMessage(getToastPayload(error.message ?? globalError, failure))
            )
        }
        return rejectWithValue(error.message)
      }
    }
  )

export default createAsyncThunkForSlice

// Function UTC TO normal date Formate

export const utcToDate = (dateString: string, isForm?: boolean) => {
  const utcDate = new Date(dateString)
  // Convert UTC date to local date
  const localDate = new Date(
    utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
  )
  const day = localDate.getDate().toString().padStart(2, '0')
  // const month = day.toLocaleString();
  const month = (localDate.getMonth() + 1).toString().padStart(2, '0')
  const year = localDate.getFullYear().toString().substr(-4)
  const formattedDate = isForm
    ? `${year}-${month}-${day}`
    : `${day}-${month}-${year}`
  return formattedDate
}

export const trimValue = (e: any) => {
  let value = e.target.value
  if (value.length === 1 && value === ' ') {
    e.target.value = ''
  } else if (
    value.length > 1 &&
    value[0] === ' ' &&
    value[value.length - 1] === ' '
  ) {
    value = value.trim()
    const words = value.split(' ')
    const filteredWords = words.filter((word: any) => word !== '')
    e.target.value = filteredWords.join(' ')
  } else if (value.length > 1 && value[0] === ' ') {
    e.target.value = value.trim()
  }
}
export function isBase64(str: any) {
  // check if string length is divisible by 4
  if (str.length % 4 !== 0) {
    return false
  }
  // regex to match base64 format
  const regex = /^[A-Za-z0-9+/]+[=]{0,2}$/
  return regex.test(str)
}

// export function isDataURI(str: any) {
//   // check if string starts with data:
//   if (!str.startsWith('data:')) {
//     return false
//   }
//   // split string at first comma
//   const parts = str.split(',')
//   if (parts.length !== 2) {
//     return false
//   }
//   // check if first part is a valid MIME type
//   const mimeTypeRegex = /^[a-z]+\/[a-z0-9-]+$/i
//   if (!mimeTypeRegex.test(parts[0].substr(5))) {
//     return false
//   }
//   // check if second part is a valid base64 string
//   return isBase64(parts[1])
// }

export function isDataUri(variable: any) {
  const pattern =
    /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9]+)?(;[a-zA-Z0-9-]+=[a-zA-Z0-9-]+)*;base64,(.*)$/
  return pattern.test(variable)
}
export const getDataUriFileSize = (dataUri: any) => {
  const byteCharacters = atob(dataUri.split(',')[1])
  const byteNumbers = new Array(byteCharacters.length)

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray])

  return blob.size
}
