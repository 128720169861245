// login
export const EMAIL = "email";
export const PASSWORD = "password";

// Forgot Password
export const countryCode = "countryCode";
export const phoneNo = "phoneNo";

// Recovery Password
export const NEW_PASSWORD = "new_password";
export const CONFIRM_NEW_PASSWORD = "confirm_password";

//manage medical
export const NAME = "mc_name";
export const PRIMARY_CONTACT_NAME = "poc_name";
export const POC_EMAIL = "poc_email";
// export const POC_PASSWORD = "password";
export const EXPIRY_DATE = "expiry_date";
export const MOBILE_NO = "poc_phone";
export const PACKAGE_ID = "package_id";
export const NO_OF_USER = "max_users_limit";
export const ATTACHMENTS = "attachments";
export const DB_URI = "db_uri";
export const NOTES = "notes";
export const ADDRESS_ID = "addressid";
export const ADDRESS_LINE_1 = "address_line_1";
export const ADDRESS_LINE_2 = "address_line_2";
export const COUNTRY = "country";
export const STATE = "state";
export const CITY = "city";
export const ZIP_CODE = "zipcode";
export const WEBSITE = "website_url";

// manage package
export const packageName = "packageName";
export const LANGUAGE = "language";

// manage package
export const PACKAGE_NAME = "name";
export const USER_LIMIT = "max_user_limit";
export const VALIDITY_DAYS = "validity_days";
export const MODULES = "modules";
export const DESCRIPTION = "description";
export const RE_DIGIT = new RegExp(/^\d+$/);

// forgot password
export const PHONE_NO = "phone";
export const FORGOT_PASSWORD_EMAIL = "email";

// otp
export const OTP = "otpPin";

// Proact News
export const NEWS_TITLE = "title";
export const NEWS_DESCRIPTION = "description";

// proact medical center
export const MEDICAL_CENTER_NAME = "mc_name";
export const MEDICAL_CENTER_ATTACHMENTS = "mc_image";

// proact doctor
export const DOCTOR_NAME = "dr_name";
export const DOCTOR_SPECIALITY = "dr_speciality";
export const DOCTOR_IMAGE = "dr_image";

// medical centet utility
export const MEDICAL_CENTER = "id";
export const DATABASE_URI = "db_uri";
export const ACCESS_KEY = "access_key";
export const BUCKET_NAME = "bucket_name";
export const SECRET_KEY = "secretKey";
export const REGION = "region";
